<script>
export default {
    components: {},
    props: {
        loading: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: ''
        },
        items: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    watch: {},
    mounted() {},
    methods: {
        handleBreadcrumb(param) {
            return param.filter((item) => item.text !== '');
        }
    }
};
</script>

<template>
    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-flex align-items-center justify-content-between">
                <h3 class="mb-0">{{ title }}</h3>
                <div class="page-title-right">
                    <b-breadcrumb :items="handleBreadcrumb(items)" class="m-0"></b-breadcrumb>
                </div>
            </div>
        </div>
        <div class="box-loading" v-if="!loading">
            <span class="loader"></span>
        </div>
    </div>
    <!-- end page title -->
</template>
<style>
.box-loading {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    top: 0;
    left: 0;
}
.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #fff;
    border-bottom-color: #ff3d00;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
