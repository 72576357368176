export const menuItems = [
    // {
    //     id: 1,
    //     label: "menuitems.menu.text",
    //     isTitle: true
    // },
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    {
        id: 5,
        label: 'menuitems.campaign.text',
        roleLv1: ['campaign.view', 'prize.view', 'return_prize.view'],
        icon: 'ri-store-2-line',
        subItems: [
            {
                id: 6,
                label: 'menuitems.campaign.list.text',
                role: 'campaign.view',
                link: '/',
                subItems: [
                    {
                        id: 7,
                        label: 'menuitems.campaign.list.list',
                        link: '/campaign/list',
                        role: 'campaign.view'
                    },
                    {
                        id: 8,
                        label: 'menuitems.campaign.list.create',
                        link: '/campaign/form',
                        role: 'campaign.register'
                    },
                    {
                        id: 8,
                        label: 'menuitems.campaign.list.document',
                        link: '/documents',
                        role: 'campaign.register'
                    }
                ]
            }
            // {
            //     id: 9,
            //     label: 'menuitems.gift.text',
            //     role: 'prize.view',
            //     link: '/',
            //     subItems: [
            //         {
            //             id: 10,
            //             label: 'menuitems.gift.list.list',
            //             link: '/prize/list',
            //             role: 'prize.view'
            //         }
            //         // {
            //         //     id: 11,
            //         //     label: 'menuitems.gift.list.create',
            //         //     link: '/prize/form',
            //         //     role: 'prize.register'
            //         // }
            //     ]
            // }
            // {
            //     id: 32,
            //     label: 'menuitems.returnGift.text',
            //     role: 'return_prize.view',
            //     link: '/',
            //     subItems: [
            //         {
            //             id: 33,
            //             label: 'menuitems.gift.list.list',
            //             link: '/prize-return/list',
            //             role: 'return_prize.view'
            //         },
            //         {
            //             id: 34,
            //             label: 'menuitems.gift.list.create',
            //             link: '/prize-return/form',
            //             role: 'return_prize.register'
            //         }
            //     ]
            // }
        ]
    },

    {
        id: 12,
        label: 'menuitems.master.text',
        roleLv1: ['client.view', 'agency.view', 'partner.view', 'employee.view', 'permission.view', 'faq.view', 'master.register'],
        icon: 'ri-store-2-line',
        subItems: [
            {
                id: 33,
                label: 'menuitems.master.userMaster',
                roleLv2: ['client.view', 'agency.view', 'partner.view', 'employee.view'],
                subItems: [
                    {
                        id: 13,
                        label: 'menuitems.master.customer.text',
                        role: 'client.view',
                        link: '/',
                        color: '#34a853',
                        bg_active: '#34a85380',
                        subItems: [
                            {
                                id: 14,
                                label: 'menuitems.master.customer.list',
                                link: '/client/list',
                                role: 'client.view'
                            },
                            {
                                id: 15,
                                label: 'menuitems.master.customer.create',
                                link: '/client/form',
                                role: 'client.register'
                            }
                        ]
                    },

                    {
                        id: 16,
                        label: 'menuitems.master.agency.text',
                        role: 'agency.view',
                        link: '/',
                        color: '#46bdc6',
                        bg_active: '#46bdc680',
                        subItems: [
                            {
                                id: 17,
                                label: 'menuitems.master.agency.list',
                                link: '/agency/list',
                                role: 'agency.view'
                            },
                            {
                                id: 18,
                                label: 'menuitems.master.agency.create',
                                link: '/agency/form',
                                role: 'agency.register'
                            }
                        ]
                    },
                    {
                        id: 19,
                        label: 'menuitems.master.partner.text',
                        role: 'partner.view',
                        link: '/',
                        color: '#4285f4',
                        bg_active: '#4285f480',
                        subItems: [
                            {
                                id: 20,
                                label: 'menuitems.master.partner.list',
                                link: '/partners',
                                role: 'partner.view'
                            },
                            {
                                id: 21,
                                label: 'menuitems.master.partner.create',
                                link: '/partner/form',
                                role: 'partner.register'
                            }
                        ]
                    },
                    {
                        id: 22,
                        label: 'menuitems.master.employee.text',
                        role: 'employee.view',
                        link: '/',
                        color: '#ea4335',
                        bg_active: '#ea433580',
                        subItems: [
                            {
                                id: 23,
                                label: 'menuitems.master.employee.list',
                                link: '/employees',
                                role: 'employee.view'
                            },
                            {
                                id: 24,
                                label: 'menuitems.master.employee.create',
                                link: '/employee/form',
                                role: 'employee.register'
                            }
                        ]
                    }
                    // {
                    //     id: 22,
                    //     label: 'menuitems.master.employeePartner.text',
                    //     link: '/',
                    //     color: '#fbbc04',
                    //     subItems: [
                    //         {
                    //             id: 23,
                    //             label: 'menuitems.master.employeePartner.list',
                    //             link: '/employee-part-time',
                    //             role: ['administrator', 'instructor', 'part_time', 'sale']
                    //         },
                    //         {
                    //             id: 24,
                    //             label: 'menuitems.master.employeePartner.create',
                    //             link: '/employee-part-time/form',
                    //             role: ['administrator', 'instructor', 'part_time']
                    //         }
                    //     ]
                    // },
                ]
            },

            // {
            //     id: 36,
            //     label:  "権限マスター",
            //     isTitle: true
            // },
            {
                id: 25,
                label: 'menuitems.master.role.text',
                link: '/',
                roleLv2: ['permission.view'],
                subItems: [
                    {
                        id: 26,
                        label: 'menuitems.master.role.list',
                        link: '/roles',
                        role: 'permission.view'
                    }
                ]
            },
            // {
            //     id: 36,
            //     label:  "FAQマスター ",
            //     isTitle: true
            // },
            {
                id: 28,
                label: 'menuitems.master.faq.text',
                roleLv2: ['faq.view'],
                link: '/',
                subItems: [
                    {
                        id: 29,
                        label: 'menuitems.master.faq.list',
                        link: '/faqs',
                        role: 'faq.view'
                    },
                    {
                        id: 30,
                        label: 'menuitems.master.faq.create',
                        link: '/faq/form',
                        role: 'faq.register'
                    }
                ]
            },
            {
                id: 31,
                label: 'menuitems.master.other.text',
                link: '/master/other',
                roleLv2: ['master.register'],
                subItems: [
                    {
                        id: 33,
                        label: 'menuitems.master.faq.list',
                        link: '/master/other',
                        role: 'master.register'
                    },
                    {
                        id: 34,
                        label: 'menuitems.master.other.holiday',
                        link: '/master/holidays',
                        role: 'master.register'
                    },
                    {
                        id: 35,
                        label: 'menuitems.master.other.holiday_year',
                        link: '/master/holiday-year',
                        role: 'master.register'
                    },
                    {
                        id: 40,
                        label: 'menuitems.master.other.manual',
                        link: '/master/manual',
                        role: 'master.register'
                    },
                    {
                        id: 36,
                        label: 'menuitems.master.other.template',
                        link: '/master/template',
                        role: 'master.register'
                    },
                    {
                        id: 37,
                        label: 'menuitems.master.other.rule',
                        link: '/master/rule',
                        role: 'master.register'
                    },
                    {
                        id: 38,
                        label: 'menuitems.master.other.rule1',
                        link: '/master/condition-rule',
                        role: 'master.register'
                    },
                    {
                        id: 39,
                        label: 'menuitems.master.other.scheduleList',
                        link: '/master/schedule/list',
                        role: 'master.register'
                    }
                ]
            }
        ]
    },

    {
        id: 17,
        label: 'menuitems.system.text',
        roleLv1: ['history.view'],
        icon: 'ri-store-2-line',
        subItems: [
            {
                id: 18,
                label: 'menuitems.system.list.history',
                role: 'history.view',
                link: '/system/history',
                subItems: []
            }
        ]
    }
];
